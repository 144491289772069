import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ApiService} from 'src/app/services/api/api.service';
import {ToolsService} from 'src/app/services/tools/tools.service';
import {Observable, Subscription} from 'rxjs';
import {GenerateXmlService} from 'src/app/services/xml/generate-xml.service';
import * as moment from 'moment';
import {RMovementClient} from '../interfaces/responses';
import {ActivatedRoute, Router} from '@angular/router';
import {first} from 'rxjs/operators';


@Component({
  selector: 'app-customers-movements',
  templateUrl: './customers-movements.component.html',
  styleUrls: ['./customers-movements.component.scss']
})
export class CustomersMovementsComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  displayedColumns: string[] = [
    'fecha', 'descripcion', 'caja', 'nro_fact',
    'descriptrans', 'proceso', 'debito', 'credito', 'saldo'
  ];
  arrayDataSource: any = [];

  loading: any = false;
  form: FormGroup;

  options: any[] = [];
  filteredOptions: Observable<any[]>;
  currencies: any = [];

  constructor(
    public apiService: ApiService,
    public tools: ToolsService,
    public snackBar: MatSnackBar,
    public xmlService: GenerateXmlService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  generateXml() {
    const arrayData: any = [];
    for (const element of this.arrayDataSource) {
      const currency = [];
      for (const item of element.dataSources.data) {
        currency.push({
          Fecha: item.updated_at,
          Tienda: item.descripcion,
          Cliente: item.name,
          Caja: item.caja,
          Factura: item.nro_fact,
          Tipo: item.descriptrans,
          Proceso: item.proceso,
          Debito: item.debito,
          Credito: item.credito,
          Saldo: item.balance
        });
      }
      arrayData.push({
        iso: element.iso,
        title: element.title,
        data: currency,
        balance: element.balance,
        acum: element.acum
      });
    }

    const filters: any = [
      {
        Titulo: 'Movimientos de Clientes',
        fecha: `${moment(this.form.value.dateStart).format('YYYY-MM-DD')}`,
        hasta: `${moment(this.form.value.dateEnd).format('YYYY-MM-DD')}`,
        cliente: `${this.form.value.client.name}`
      }
    ];

    this.xmlService.exportAsExcelFile(filters, arrayData, `Movimientos de Clientes - ${moment().format('YYYY-MM-DD H:m:s')}`);
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options
      .filter(
        option => option.name.toLowerCase().indexOf(filterValue) === 0
          || option.id_number.toLowerCase().indexOf(filterValue) !== -1
          || option.email.toLowerCase().indexOf(filterValue) === 0
      );
  }

  initForm() {
    this.form = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      dateStart: new FormControl(null, [Validators.required]),
      dateEnd: new FormControl(null, [Validators.required])
    });

    this.subscription = this.route.queryParams
      .subscribe(({client_id, name, dateStart, dateEnd}) => {
        if (client_id) {
          this.form.get('client').setValue({id: client_id, name});
        }
        if (dateStart) {
          this.form.get('dateStart').setValue(dateStart);
        }
        if (dateEnd) {
          this.form.get('dateEnd').setValue(dateEnd);
        }
        if (client_id && dateStart && dateEnd) {
          this.filters();
        }
      });
  }

  getClients() {
    const value = this.form.get('client').value;
    if (value && value.length > 1) {
      this.apiService
        .getclients(this.form.get('client').value)
        .subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              this.options = response.clients;
            }
          }
        });
    } else {
      this.options = [];
    }
  }

  filters() {
    this.loading = true;
    this.arrayDataSource = [];
    this.apiService.filterMovementsClients({
      start: moment(this.form.value.dateStart).format('YYYY-MM-DD'),
      end: moment(this.form.value.dateEnd).format('YYYY-MM-DD'),
      client_id: this.form.value.client.id
    }).subscribe({
      next: (res: RMovementClient) => {
        this.loading = false;
        if (res.code !== 200) {
          return;
        }
        if (res.movements.length === 0) {
          this.snackBar.open('No hay Movimiento', 'ok', {duration: 5000});
        }
        for (const data of res.details) {
          this.arrayDataSource.push({
            dataSources: new MatTableDataSource<any>(),
            balance: data.preview,
            title: data.descripcion,
            iso: data.iso,
            wallet_id: data.wallet_id
          });
          const array = [];
          let acum = data.preview;
          for (const mov of res.movements) {
            if (mov.moneda === data.moneda) {
              acum = acum + mov.credito - mov.debito;
              mov.balance = acum;
              array.push(mov);
            }
          }
          this.arrayDataSource[this.arrayDataSource.length - 1].dataSources.data = array;
          this.arrayDataSource[this.arrayDataSource.length - 1].acum = acum;
        }
      },
      error: (err) => {
        this.loading = false;
        this.snackBar.open('Error cargando los Movimiento', 'ok', {duration: 5000});
      }
    });
  }

  async onChangeQueryParams() {
    const queryParams = {
      client_id: this.form.get('client').value.id,
      name: this.form.get('client').value.name,
      dateStart: moment(this.form.value.dateStart).format('YYYY-MM-DD'),
      dateEnd: moment(this.form.value.dateEnd).format('YYYY-MM-DD')
    };
    await this.router.navigate([], {queryParams});
  }

  clearValues() {
    this.form.get('client').setValue(null);
    this.form.get('dateStart').setValue(null);
    this.form.get('dateEnd').setValue(null);
  }

  focusout(event) {
    if (typeof (this.form.get('client').value) === 'string' && this.form.get('client').value && this.form.get('client').value.length > 1) {
      const array = this._filter(this.form.get('client').value);
      if (array.length > 0) {
        this.form.get('client').setValue(array[0]);
      }
    }
  }
}
