<div class="padding-box">
  <div class="panel-buttons">
    <button *ngIf="user.rol != 3" [routerLink]="['../']" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h3 *ngIf="user.rol != 3" class="line-title">{{ editMode ? 'Editar' : 'Crear' }} Empresa</h3>
    <h3 *ngIf="user.rol == 3" class="line-title">Perfil Empresa</h3>
    <div class="spacer"></div>
    <button *ngIf="!editMode" [disabled]="!form.valid || checkoutMoneySelect().length == 0"  matTooltip="Guardar" matTooltipPosition="below" (click)="onSubmit()" style="background: #e60000; margin-right: 10px;" mat-fab><mat-icon>save</mat-icon></button>
    <button *ngIf="editMode" [disabled]="edit && (!form.valid || checkoutMoneySelect().length == 0)" (click)="callSubmit(); enablesInputs(true);" matTooltip="{{!editMode || edit ? 'Guardar' : 'Editar'}}" matTooltipPosition="below" style="background: #e60000; margin-right: 10px;" mat-fab><mat-icon>{{!editMode || edit ? 'save' : 'edit' }}</mat-icon></button>
    <button *ngIf="editMode && edit" (click)="enablesInputs(false)" style="background: #e60000; margin-right: 10px;" mat-fab matTooltip="Cancelar" matTooltipPosition="below"><mat-icon>cancel</mat-icon></button>
  </div>


  <div style="display: inline-flex; width: 100%;">
    <mat-card style="overflow:auto; margin-bottom: 50px; width: 50%; margin: 1em;">
      <mat-card-header>
        <mat-card-title>Datos de la empresa</mat-card-title>
        <mat-card-subtitle>Verifica los datos al registrar. El Rif no se podrá modificar.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form *ngIf="form" [formGroup]="form" autocomplete="off">
          <div class="box">
            <div style="width: 10%;">
              <mat-form-field style="width: 100%;">
                <mat-select placeholder="Tipo" formControlName="typeRif">
                  <mat-option value="CC">CC</mat-option>
                  <mat-option value="CE">CE</mat-option>
                  <mat-option value="PAS">PAS</mat-option>
                  <mat-option value="NIT">NIT</mat-option>
                </mat-select>
                <mat-error>El tipo es requerido</mat-error>
              </mat-form-field>
            </div>
            <div style="width: 30%;">
              <mat-form-field floatLabel="auto" style="width: 100%;">
                <mat-label>RIF</mat-label>
                <input matInput placeholder="Ingrese el Rif" formControlName="rif" (keypress)="tool.numberOnly($event)">
                <mat-error>El Rif es requerido</mat-error>
              </mat-form-field>
            </div>

            <div  style="width: 30%;">
              <mat-checkbox formControlName="estado" color="primary">{{ !form.get('estado').value ? 'Activar' : 'Desactiva' }} Compañia</mat-checkbox>
            </div>
          </div>
          <div class="box">
            <div>
              <mat-form-field  floatLabel="auto" style="width: 100%;">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Ingrese el nombre" formControlName="name">
                <mat-error>El nombre es requerido</mat-error>
              </mat-form-field>
            </div>

            <div>
              <mat-form-field  floatLabel="auto" style="width: 100%;">
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="Ingrese el teléfono" formControlName="phone">
                <mat-error *ngIf="form.get('phone').errors?.required">El teléfono es requerido</mat-error>
                <mat-error *ngIf="form.get('phone').errors?.pattern">El Formato del teléfono no es valido</mat-error>
                <mat-error *ngIf="form.get('phone').errors?.maxlength">El teléfono debe ser menor a 16 números</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div style="margin: auto; text-align: center;">
            <mat-form-field  floatLabel="auto" style="width: 90%;">
              <mat-label>Dirección</mat-label>
              <input matInput placeholder="Ingrese la dirección" formControlName="direction">
              <mat-error>La dirección es requerida</mat-error>
            </mat-form-field>
          </div>

          <mat-divider></mat-divider>
          <mat-card-subtitle style="margin-top: 1em;">Datos de inicio de sesión</mat-card-subtitle>
          <div class="box">
            <div>
              <mat-form-field  floatLabel="auto" style="width: 100%;">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Ingrese email" formControlName="email">
                <mat-error *ngIf="form.get('email').errors?.required">El email es requerido</mat-error>
                <mat-error *ngIf="form.get('email').errors?.email">El email no es valido</mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  floatLabel="auto" style="width: 100%;">
                <mat-label>Contraseña</mat-label>
                <input matInput type="password" placeholder="Ingrese contraseña" formControlName="password">
                <mat-error *ngIf="form.get('password').errors?.required">La contraseña es requerida</mat-error>
                <mat-error *ngIf="form.get('password').errors?.minlength">La contraseña debe tener min 4 caracteres.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card style="overflow:auto; margin-bottom: 50px; width: 50%; margin: 1em;">
      <mat-card-header>
        <mat-card-title>Datos del Contacto</mat-card-title>
        <mat-card-subtitle>Información del gestor de la empresa</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <form *ngIf="form" [formGroup]="form" autocomplete="off">
          <div class="box">
            <div>
              <mat-form-field  floatLabel="auto">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Ingrese el nombre del contacto" formControlName="name_contact">
                <mat-error>El nombre del contacto es requerido</mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field  floatLabel="auto">
                <mat-label>Teléfono</mat-label>
                <input matInput placeholder="Ingrese el teléfono" formControlName="phone_contact">
                <mat-error *ngIf="form.get('phone_contact').errors?.required">El teléfono es requerido</mat-error>
                <mat-error *ngIf="form.get('phone_contact').errors?.pattern">El Formato del teléfono no es valido</mat-error>
                <mat-error *ngIf="form.get('phone_contact').errors?.maxlength">El teléfono debe ser menor a 16 números</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <div>
          <mat-divider></mat-divider>
          <br>
          <mat-card-subtitle style="margin-top: 1em;">Modedas administradas</mat-card-subtitle>

          <mat-list>
            <mat-list-item *ngFor="let currency of currencies">
              <mat-checkbox [disabled]="editMode && !edit" [(ngModel)]="currency.estado" color="primary">{{ currency.iso }}</mat-checkbox>
            </mat-list-item>
          </mat-list>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <mat-card style="margin: 1em;" *ngIf="editMode && user.rol != 3">
    <mat-card-header>
      <mat-card-title>Archivos</mat-card-title>
      <mat-card-subtitle>Listado de archivos cargados</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <app-company-files *ngIf="companyId" [reload]="reload" [companyId]="companyId"></app-company-files>
    </mat-card-content>
  </mat-card>

  <div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner ></mat-spinner>
  </div>

</div>
