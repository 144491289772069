import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ApiService} from '../services/api/api.service';
import {ToolsService} from '../services/tools/tools.service';
import * as moment from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {DialogGenComponent} from '../dialog-gen/dialog-gen.component';
import {SesionService} from '../services/sesion/sesion.service';
import {ChangeStatusComponent} from '../change-status/change-status.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {
  loading: any;
  @Input() data: Array<any>;
  @Input() fileName: string;
  @Input() currency: any;
  @Input() companyId: any;
  @Input() userId: any;
  @Input() onlyView: any;
  @Input() estado: any;
  @Input() fileId: any;
  @Output() close: EventEmitter<any> = new EventEmitter();

  displayedColumns: any = ['nombre', 'cedula',  'email', 'monto', 'estado'];
  dataSource = new MatTableDataSource<any>();
  usersConfirmed: any = [];
  total: any = 0;
  arrayNewUser: any = [];
  newUsers: any = 0;
  positionNombre: any;
  positionCedula: any;
  positionMonto: any;
  positionEmail: any;
  successProcess: any = false;
  user: any;
  progres: any;
  interval;
  repeatUsers = false;
  conflictUsers: any = false;

  constructor(public apiService: ApiService, public tool: ToolsService, public dialog: MatDialog, private sesion: SesionService) {}

  emitBack(): any {
    this.close.emit(false);
  }

  changeStatus(): void {
    if (this.user.rol === 2 || this.user.rol === 3) { // Solo el admin puede modificar. El Lugar solo puede rechazar
      const dialogRef = this.dialog.open(ChangeStatusComponent, {
        width: '380px',
        data: { row: {estadoFile: this.estado}, rolUser: this.user.rol }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.apiService.getFileStatus(this.fileId).subscribe((file: any ) => {
            if (file?.length > 0 && file[0]?.estado === 1) {
              this.updateStatus(result?.estado, this.fileId, this.estado);
            } else {
              this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada el archivo ya ha cambiado de estado.', 0);
            }
            console.log(file);
          }, err => {
            this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada el archivo ya ha cambiado de estado.', 0);
          });

        }
        dialogRef.close();
      });
    }
  }

  updateStatus(estado, fileId, oldStatus): any {
    this.loading = true;
    this.apiService.updateStatusFile({estado, fileId, oldStatus}).subscribe((response) => {
      if (response?.success) {
        this.estado = estado;
      } else {
        this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada correctamente.', 0);
      }
      this.loading = false;
    }, (err) => {
      this.loading = false;
      this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada correctamente.', 0);
      console.log(err);
    });
  }

  verifyUsers(load: any = false): any {
    this.checkColumnPosition();
    let array = [];
    for (let i = 1; i < this.data.length; i++) {
      array.push({id_number: `${this.data[i][this.positionCedula]}`.toLocaleUpperCase()});
    }
    if (array.length > 0) {
      this.apiService.verifyUsers({users: array, currency: this.currency.codigo}).subscribe((response: any) => {
        this.usersConfirmed = response;
        this.prepareData();
        if (load) {
          this.loadOnlyFile();
        } else {
          this.loading = false;
        }
      }, (err: any) => {
        this.loading = false;
        console.log(':::::::Err ', err);
      });
    }
  }

  checkColumnPosition(): void {
    this.data[0].forEach((item, index) => {
      switch (`${item}`.toLocaleUpperCase()) {
        case 'NOMBRE':
          this.positionNombre = index;
          break;
        case 'CEDULA':
          this.positionCedula = index;
          break;
        case 'MONTO':
          this.positionMonto = index;
          break;
        case 'EMAIL':
          this.positionEmail = index;
          break;
      }
    });
  }

  getNameStatus(value: any, repeat: any, withoutDocument: any): string {
    if (withoutDocument) {
      return 'Sin Documento';
    }

    if (repeat) {
      return 'Repetido';
    }
    return value === 0 ? 'No Existe' : 'Verificado';
  }

  public getStatus(status): string {
    let label;
    if (status === 1) {
      label = 'Diferidos';
    } else if (status === 2) {
      label = 'Aprobados';
    } else if (status === 3) {
      label = 'Rechazado';
    }
    return label;
  }

  prepareData(): void {
    let array = [];
    this.arrayNewUser = [];
    this.total = 0;
    this.newUsers = 0;

    for (let i = 1; i < this.data.length; i++) {
      const find = this.usersConfirmed.filter(
        (elem: any) => `${elem.id_number}`.toLocaleUpperCase() === `${this.data[i][this.positionCedula]}`.toLocaleUpperCase()
      );

      array.push({
        nombre: this.data[i][this.positionNombre],
        cedula: this.data[i][this.positionCedula] ? `${this.data[i][this.positionCedula]}`.toLocaleUpperCase() : undefined,
        monto: this.data[i][this.positionMonto],
        email: `${this.data[i][this.positionEmail]}`.toLocaleLowerCase(),
        estado: find?.length > 0 ? 1 : 0,
        cliente_id: find?.length > 0 ? find[0].id : null,
        wallet_id: find?.length > 0 ? find[0].wallet_id : null,
        moneda: this.currency.codigo,
        factor: 0,
        localidad: 997,
        nro_fact: 0,
        caja: 0,
        id: undefined
      });

      if (find?.length === 0) {
        this.arrayNewUser.push({
          name: this.data[i][this.positionNombre],
          id_number: `${this.data[i][this.positionCedula]}`.toLocaleUpperCase(),
          email: `${this.data[i][this.positionEmail]}`.toLocaleLowerCase(),
        });
      }

      this.newUsers = this.newUsers + (find?.length > 0 ? 0 : 1);
      this.total = this.total + Number(this.data[i][this.positionMonto]);
    }

    for (const item of array) {
      item.repeat = array.filter((elem: any) => elem.cedula === item.cedula).length > 1;
      if (item.repeat) {
        this.repeatUsers = true;
      }

      if (!item.cedula) {
        this.conflictUsers = true;
        item.withoutData = true;
      }
    }

    this.dataSource.data = array;
  }

  loadOnlyFile(): any {
    this.apiService.loadFile({
      file: {
        nombre: this.fileName,
        fecha_carga: moment().format('YYYY-MM-DD hh:mm:ss'),
        estado: 1,
        moneyCod: this.currency.codigo,
        monto: this.total,
        companyId: this.companyId,
        userId: this.userId ? this.userId : 0,
      },
      users: this.dataSource.data
    }).subscribe((load) => {
      if (load?.success) {
        this.apiService.postWalletDetail({details: this.dataSource.data, fileId: load.fileId}).subscribe((data: any) => {
          this.clearInterval();
          this.initInterval(10, true);
          setTimeout(() => {
            this.loading = false;
            this.clearInterval();
          }, 3000);
          this.openDialog('Información', 'Felicidades se ha cargado el archivo con exito.', 1);
        }, (err: any) => {
          this.loading = false;
          this.openDialog('Error', '¡Lo sentimos! no logramos procesar su archivo correctamente.', 0);
        });
      } else {
        this.loading = false;
        this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada correctamente.', 0);
      }
    }, (err: any) => {
      console.log(err);
      this.loading = false;
      this.openDialog('Error', '¡Lo sentimos! no logramos procesar su archivo correctamente.', 0);
    });
  }

  initInterval(time, finish: any = false): any {
    this.interval = setInterval(() => {
      if (this.progres < 95 || finish) {
        this.progres++;
      }
    }, time);
  }

  clearInterval(): any {
    clearInterval(this.interval);
  }

  loadFile(): any {
    this.loading = true;
    this.progres = 1;
    this.initInterval(2000);
    if (this.arrayNewUser.length > 0) {
      this.createNewUsers();
    } else {
      this.loadOnlyFile();
    }
  }

  createNewUsers(): any {
    this.apiService.postClientNew({users: this.arrayNewUser}).subscribe((data: any) => {
      console.log(data);
      if (data?.success) {
        this.verifyUsers(true);
      } else {
        this.openDialog('Información', 'Lo sentimos, ha ocurrido un error.', 0);
      }
    }, (err: any) => {
      console.log(err);
      this.loading = false;
      this.openDialog('Error', '¡Lo sentimos! no logramos procesar los nuevos usuarios.', 0);
    });
  }

  ngOnInit(): void {
    this.user = this.sesion.getUser();
    this.loading = true;
    this.verifyUsers();
  }

  openDialog(titleModal: string, messageModal: string, type): void {
    const dialogRef = this.dialog.open(DialogGenComponent, {
      width: '380px',
      data: { title: titleModal, message: messageModal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        if (type === 1) {
          this.successProcess = true;
          this.emitBack();
        }
      }
      dialogRef.close();
    });
  }
}
