import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class GenerateXmlService {

  constructor() { }

  exportAsExcelFile(filters: any[], data: any[], excelFileName: string): void {
    let workbook: XLSX.WorkBook;
    let i = 0;
     for (let item of data) {
       let worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filters);
       XLSX.utils.sheet_add_json(worksheet, [{Moneda: item.title, Inicial: item.balance}], {origin: "A4"});
       XLSX.utils.sheet_add_json(worksheet, item.data, {origin: "A7"});
       XLSX.utils.sheet_add_json(worksheet, [{Fecha: 'Total'} ], {skipHeader: true, origin: `A${item.data.length + 9}`});
       XLSX.utils.sheet_add_json(worksheet, [{Saldo: item.acum}], {skipHeader: true, origin: `J${item.data.length + 9}`});
       worksheet["!cols"] = this.getWidth(item.data);

       if (i == 0) {
         if (item.iso == 'USD') {
           workbook = { Sheets: { 'USD': worksheet }, SheetNames: ['USD'] };
         } else {
           workbook = { Sheets: { 'COP': worksheet }, SheetNames: ['COP'] };
         }
       } else {
         XLSX.utils.book_append_sheet(workbook, worksheet, `${item.iso}`);
       }
       i++;
     }

     const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
     this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  getWidth(json) {
    let objectMaxLength = [];
    for (let i = 0; i < json.length; i++) {
      let value = <any>Object.values(json[i]);
      for (let j = 0; j < value.length; j++) {
        if (typeof value[j] == "number") {
          objectMaxLength[j] = 15;
        } else {
          let characters = objectMaxLength[j] >= value[j].length ? objectMaxLength[j] : value[j].length;
          objectMaxLength[j] = characters < 10 ? 10 : characters;

        }
      }
    }

    var wscols = [];
    for (let index = 0; index < objectMaxLength.length; index++) {
      wscols.push({ width: objectMaxLength[index] });
    }

    return wscols;
  }

  // const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  // XLSX.utils.sheet_add_json(worksheet, [
  //     { A: 1, B: 2 }, { A: 2, B: 3 }, { A: 3, B: 4 }
  //   ], {skipHeader: true, origin: "A10"}
  // );
  // const workbook: XLSX.WorkBook = { Sheets: { 'USD': worksheet }, SheetNames: ['USD'] };
  //
  // const ws2 = XLSX.utils.json_to_sheet(
  //   [{ A: 'timezone offset', B: '-timezone' }],
  //   { skipHeader: true, header: ['A', 'B'] }
  // )
  //
  // XLSX.utils.book_append_sheet(workbook, ws2, 'COP');
  //
  // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  // this.saveAsExcelFile(excelBuffer, excelFileName);

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, `${fileName}${EXCEL_EXTENSION}`);
  }
}
