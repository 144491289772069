import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from 'src/app/services/api/api.service';
import { ToolsService } from 'src/app/services/tools/tools.service';
import { GeneratePdfService } from 'src/app/services/pdf/generate-pdf.service';
import * as moment from 'moment';
import {SesionService} from '../services/sesion/sesion.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-account-status',
  templateUrl: './account-status.component.html',
  styleUrls: ['./account-status.component.scss']
})
export class AccountStatusComponent implements OnInit {
  displayedColumns: string[] = ['divisa', 'iso', 'monto'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('containerTable', { read: ElementRef }) containerTable: ElementRef;

  pageIndex = '0';
  pageSize = '10';
  sortBy = 'divisa';
  sortDir = 'asc';
  loading: any = false;

  constructor(
    public apiService: ApiService,
    public tools: ToolsService,
    public pdfService: GeneratePdfService,
    public sesion: SesionService,
    public router: Router
  ) { }

  ngOnInit(): void {
    const user = this.sesion.getUser();
    if (user?.rol === 3) {
      this.router.navigate(['account'], {replaceUrl: true});
    } else {
      this.apiService.getMoneySumary().subscribe(response => {
        if (response['code'] === 200) {
          this.dataSource.data = response['movements'];
        }
        this.loading = false;
      }, err => {
        this.loading = false;
        console.log('::::', err);
      });
    }
  }

  generatePDF(): void {
    this.pdfService.autoTable(
      ['Descripcion', 'Código', 'Monto'],
      this.generateInfo(this.dataSource.data),
      'Estado de cuenta',
      `Estado de cuenta ${moment().format('YYYY-MM-DD Hms')}`,
      false,
      []
    );
  }

  generateInfo(data): any {
    let array = [];
    for (let item of data) {
      let info = [
        item.descripcion,
        item.iso,
        this.tools.formatDisplay(item.monto, 3, 3, '.', ',', ' ')
      ];
      array.push(info);
    }
    return array;
  }

  resetIndexAndLoadData(): void {
    console.log(this.sort.direction);
    let active = this.sort.active === 'divisa' ? 'descripcion' : this.sort.active;
    this.dataSource.data = this.tools.orderList(this.dataSource.data, active, this.sort.direction);
  }
}
