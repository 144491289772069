<h1 mat-dialog-title>Subir Archivo</h1>
<div mat-dialog-content style="min-height: 100px;">
  <p>¿Que tipo de moneda desea cargar?</p>
  <mat-radio-group aria-label="Select an option" [(ngModel)]="currency">
    <mat-radio-button *ngFor="let currency of currencies" class="radio-button" [value]="currency">{{currency.descripcion}}</mat-radio-button>
  </mat-radio-group>
  <div style="padding: 1em 0;">
    <input type="file" (change)="onXLSXSelected($event.target)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
    <mat-label style="font-size: 0.8em; color: #ff4081;" *ngIf="fileContent && !fileValid">Archivo invalido</mat-label>
  </div>
  <mat-label style="font-size: 0.8em;"><b>Importante:</b> <i>Para poder procesar el archivo debe contener las cabeceras email, cedula, nombre y monto</i></mat-label>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Gracias</button>
  <button mat-button [disabled]="!currency || !fileValid" [mat-dialog-close]="{currency: currency, fileContent: fileContent, fileName: name}">Validar</button>
</div>

