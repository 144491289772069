import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import CryptoJS from 'crypto-js';
import {RCurrenciesInfo, RMovementClient, RPaginate, RWallet} from "../../interfaces/responses";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /* Conexion Local*/
  // url = 'http://localhost:3000/api';
  /* Conexion al api de dev*/
  url = 'https://devapi.superlosmontes.com/api';
  /* Conexion al api de prod*/
  //url = 'https://api.superlosmontes.com/api';
  secret: any = `m0neder0G4rzon*#`;

  constructor(private http: HttpClient) {
  }

  public authUser(emailUser: any, password: any, company: any): any {
    const headersHttp = new HttpHeaders();
    headersHttp.append('Content-Type', 'application/x-www-form-urlencoded');

    const encrypted = CryptoJS.AES.encrypt(password, this.secret).toString();
    const body = {
      email: emailUser,
      password: encrypted,
      isCompany: company
    };

    const url = company ? 'loginCompany' : 'authuser';
    return this.http.post(`${this.url}/${url}`, body, {headers: headersHttp});
  }

  public getSummary(value: {
    dateStart: string, dateEnd: string, client: string, currency: string,
    pageIndex: string, pageSize: string, sortBy: string, sortDir: string
  }): Observable<{ code: number; data: RPaginate<RWallet> }> {
    const params = new HttpParams({fromObject: value});
    return this.http.get<{ code: number; data: RPaginate<RWallet> }>(`${this.url}/wallet/listClients`, {params});
  }

  public getMoneySumary(): any {
    return this.http.get(`${this.url}/wallet/moneySummary`);
  }

  public getCurrencies(): Observable<RCurrenciesInfo> {
    return this.http.get<RCurrenciesInfo>(`${this.url}/currencystore/info`);
  }

  public filterMovements(body: any): any {
    return this.http.post(`${this.url}/wallet/filterMovements`, body);
  }

  public filterMovementsClients(body: any): Observable<RMovementClient> {
    return this.http.post<RMovementClient>(`${this.url}/wallet/filterMovementsClients`, body);
  }

  public getclients(value): any {
    return this.http.get(`${this.url}/clientsFilter/${value}`);
  }

  public getCompanies(): any {
    return this.http.get(`${this.url}/companies`);
  }

  public getOneCompany(id): any {
    return this.http.get(`${this.url}/company/${id}`);
  }

  public getCurrenciesCompany(id): any {
    return this.http.get(`${this.url}/currenciesCompany/${id}`);
  }

  public createCompany(body, update): any {
    if (update) {
      body.id = update;
      return this.http.put(`${this.url}/companies`, body);
    } else {
      return this.http.post(`${this.url}/companies`, body);
    }
  }

  public updateStatusCompany(body): any {
    return this.http.put(`${this.url}/statusCompany`, body);
  }

  getFiles(companyId): any {
    return this.http.get(`${this.url}/files/${companyId}`);
  }

  verifyUsers(body): any {
    return this.http.post(`${this.url}/users/verify`, body);
  }

  loadFile(body): any {
    return this.http.post(`${this.url}/files`, body);
  }

  updateStatusFile(body: any): any {
    return this.http.put(`${this.url}/files/updateStatus`, body);
  }

  getOneFile(id: any): any {
    return this.http.get(`${this.url}/files/one/${id}`);
  }

  getFileStatus(id: any): any {
    return this.http.get(`${this.url}/files/status/${id}`);
  }

  postClientNew(users: any): any {
    return this.http.post(`${this.url}/clients/wallet`, users);
  }

  postWalletDetail(users: any): any {
    return this.http.post(`${this.url}/walletsdetails/file`, users);
  }

  getWallets(idNumber: any): any {
    let headers: any;
    headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.get(`${this.url}/clientwallet/${idNumber}`);
  }

  tokenRequest(body): any {
    let headers: any;
    headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    return this.http.post(`${this.url}/wallet/token`, body, {headers});
  }

  getVerifyToken(body): any {
    return this.http.post(`${this.url}/users/keyphone`, body);
  }

  recovery(body): any {
    console.log(body);
    if (body.isCompany) {
      return this.http.post(`${this.url}/company/recovery`, body);
    } else {
      return this.http.post(`${this.url}/user/recovery`, body);
    }
  }
}
