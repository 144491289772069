<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content class="mat-typography">
  <mat-nav-list style="width: 50%; margin: auto;">
    <div style="padding: 0.5em; cursor: pointer; margin: auto; margin: 1em; border-radius: 1.5em; color: white; text-align: center;" *ngFor="let item of status" (click)="selectStatus(item)"
      [ngClass]="{'verify': item.value == 2, 'deferred': item.value == 1, 'rejected': item.value == 3}"> {{ item.name }}
      </div>
  </mat-nav-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancelar</button>
</mat-dialog-actions>
