import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }

  public orderList(objs, key, type) {
    return type !== 'desc' ? objs.sort((a,b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0)) :
                           objs.sort((a,b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0));
  }

  orderListNumbers(objs, key, type) {
    return type !== 'desc' ? objs.sort((a,b) => (Number(a[key]) > Number(b[key])) ? 1 : ((Number(b[key]) > Number(a[key])) ? -1 : 0)) :
                           objs.sort((a,b) => (Number(a[key]) < Number(b[key])) ? 1 : ((Number(b[key]) < Number(a[key])) ? -1 : 0));
  }

  /**
   * formatDisplay(value, n, x, s, c)
   *
   * @param integer value: number
   * @param integer n: length of decimal
   * @param integer x: length of whole part
   * @param mixed   s: sections delimiter
   * @param mixed   c: decimal delimiter
   * output: (12345678.9, 2, 3, '.', ',');  --> "$ 12.345.678,90"
   * output (123456.789, 4, 4, ' ', ':');   --> "$ 12 3456:7890"
   * output (12345678.9, 3, '-');           --> "$ 12-345-679"
  */

  public formatDisplay(value, n, x, s, c, simbol = '$'): any {
    value = typeof(value) === 'number' ? value : Number(value);
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = value.toFixed(Math.max(0, ~~n));

    return `${simbol} ${(c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','))}`;
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
