import {Component, Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import readXlsxFile from 'read-excel-file';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load-modal.component.html',
  styleUrls: ['./load-modal.component.scss']
})
export class LoadModalComponent {

  currencies: any = [];
  currency: any;
  fileContent: any;
  name: any;
  headers: Array<string> = ['email', 'nombre', 'cedula', 'monto'];
  fileValid: any = false;

  constructor(
    public dialogRef: MatDialogRef<LoadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data?.currencies) {
      this.currencies = data?.currencies;
    }
  }

  onNoClick(): any {
    this.dialogRef.close();
  }

  onXLSXSelected(event): void {
    const file = event.files[0];
    this.name = file?.name;
    readXlsxFile(file).then((rows) => {
      this.fileContent = rows;
      if (this.fileContent.length > 0) {
        console.log(this.fileContent[0]);
        this.fileValid = this.checkHeaders(this.fileContent[0]);
      } else {
        this.fileValid = false;
      }
      console.log('valid::::', this.fileValid);
    }, (err) => {
      console.log('::::Error:::', err);
    });
  }

  checkHeaders(row: Array<any>): any {
    let valid = true;
    this.headers.forEach(item => {
      if (!row.find(elem => elem.toLocaleUpperCase() === item.toLocaleUpperCase())) {
        valid = false;
      }
    });
    return valid;
  }

  onCSVSelected(event): void {
    const file = event.files[0];
    const fileReader: FileReader = new FileReader();
    const self = this;
    this.name = file?.name;
    fileReader.onloadend = (x) => {
      self.fileContent = fileReader.result;
      console.log(self.fileContent);
    };
    fileReader.readAsText(file);
  }
}
