import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToolsService} from '../services/tools/tools.service';
import {ApiService} from '../services/api/api.service';
import {DialogGenComponent} from '../dialog-gen/dialog-gen.component';
import {MatDialog} from '@angular/material/dialog';

let instance;
@Component({
  selector: 'app-payment-panel',
  templateUrl: './payment-panel.component.html',
  styleUrls: ['./payment-panel.component.scss']
})
export class PaymentPanelComponent implements OnInit {
  form: FormGroup;
  min: any = 5;
  seconds: any = 0;
  flats: any = false;
  timeBuy: any;
  interval: any;
  loading: any;
  wallets: any;
  client: any;
  response: any;
  key: any;


  constructor(public tool: ToolsService, public api: ApiService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.initilize();
    instance = this;
  }

  private initilize(): void {
    this.form = new FormGroup({
      typeRif: new FormControl('V', [Validators.required]),
      rif: new FormControl(null, [Validators.required]),
    });
  }

  public async generateToken(item: any): Promise<void> {
    this.loading = true;
    this.api.getVerifyToken({id_number: item.id_number, keyPhone: this.key}).subscribe((data: any) => {
      if (data?.length > 0) {
        this.api.tokenRequest({
          wallets: [{
            id : item.wallet_id,
            moneda: item.moneda,
            client_id: item.client_id
          }]
        }).subscribe(tokens => {
          if (tokens?.code  === 200) {
            this.response = tokens?.data[0].wallet;
            this.openDialog(`Token Generado ${item.descripcion}` , `${this.response.token}`, false, true);
          } else {
            this.openDialog('Información', 'No se logro obtener el token.');
          }
          this.loading = false;
        }, err => {
          this.loading = false;
          console.log(err);
          if (err?.error?.code === 400) {
            this.openDialog('Información', err['error']['message'] ? err['error']['message'] : '¡Ups! tenemos un problema.');
          } else {
            this.openDialog('Información', '¡Ups! tenemos un problema.');
          }
        });
      } else {
        this.loading = false;
        this.openDialog('Información', 'Clave telefonica incorrecta');
      }
    });
  }

  public searchClient(): void {
    this.loading = true;
    this.api.getWallets(`${this.form.get('typeRif').value}${this.form.get('rif').value}`)
      .subscribe((response: any) => {
        if (response?.code === 200) {
          this.interval = setInterval(this.timer , 1000);
          this.flats = true;
          this.wallets = response.data;
          this.form.get('rif').disable();
        } else if (response?.code === 204) {
          this.openDialog('Información', response?.message);
        }
        console.log(response);
        this.loading = false;
      }, (err: any) => {
        this.loading = false;
        console.log(err);
      });
  }

  public reset(): any {
    this.resetInterval();
    this.min = 5;
    this.seconds = 0;
    this.form.get('rif').enable();
    this.wallets = undefined;
    this.flats = false;
    this.timeBuy = undefined;
    this.response = undefined;
    this.initilize();
  }

  public resetInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  openDialog(titleModal: string, messageModal: string, reset = false, reload = false): void {
    const dialogRef = this.dialog.open(DialogGenComponent, {
      width: '380px',
      data: { title: titleModal, message: messageModal}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.key = undefined;
      if (result && reset) {
        this.reset();
      }

      console.log(result, '  ', reload);
      if (result && reload) {
        this.searchClient();
        this.resetInterval();
      }
      dialogRef.close();
    });
  }

  public timer(): void {
    if (instance.flats) {
      instance.seconds--;
      if (instance.seconds < 0) {
        instance.min--;
        instance.seconds = instance.min < 0 ? 0 : 59;
      }

      if (instance.min < 0) {
        instance.flats = false;
        instance.resetInterval();
        instance.openDialog('Información', 'Lo sentimos su tiempo se agoto.', true, false);
      }

      if (instance.flats) {
        instance.timeBuy = `${instance.min + ':'}${instance.seconds < 10 ? '0' + instance.seconds : instance.seconds}`;
      }
    }
  }
}
