import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-change-status',
  templateUrl: './change-status.component.html',
  styleUrls: ['./change-status.component.css']
})
export class ChangeStatusComponent implements OnInit {
  title: any = 'Cambiar estado';
  status: any = [];

  constructor(public dialog: MatDialogRef<ChangeStatusComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.listStatus(this.data?.row?.estadoFile);
  }

  listStatus(status): any {
    if (status === 1) {
      if (this.data?.rolUser === 2) {
        this.status = [
          { name: 'Aprobado', value: 2 },
          {name: 'Rechazado', value: 3 }
        ];
      } else {
        this.status = [
          {name: 'Rechazado', value: 3 }
        ];
      }
    }

    if (status === 2) {
      this.status = [];
    }

    if (status === 3) {
      this.status = [];
    }
  }

  selectStatus(item): any {
    this.dialog.close({ estado: item.value });
  }

}
