<div class="padding-box">
  <div class="panel-buttons">
    <h3 class="line-title">Empresas</h3>
    <div class="spacer"></div>

    <mat-form-field floatLabel="auto" style="width: 40%;" appearance="outline">
      <mat-label>Nombre empresa</mat-label>
      <input matInput [(ngModel)]="search" type="text" placeholder="Buscar empresa" (input)="searchCompany()">
    </mat-form-field>

    &nbsp;
    &nbsp;
    <button [routerLink]="'./new'" style="background: #e60000; margin-right: 10px;" mat-fab><mat-icon>add</mat-icon></button>
    <button matTooltip="Info about the action"
            aria-label="Button that displays a tooltip when focused or hovered over"
            [disabled]="dataSource.data.length == 0" (click)="generatePDF()" style="background: #e60000; margin-right: 10px;" mat-fab>
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>


  <mat-card style="overflow:auto; margin-bottom: 50px;">
    <mat-table *ngIf="displayedColumns.length > 0" [dataSource]="dataSource" matSort [matSortActive]="sortBy" [matSortDirection]="sortDir" matSortDisableClear
               (matSortChange)="resetIndexAndLoadData()">

      <ng-container matColumnDef="rif">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Rif </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.rif }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.nombre_empresa }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="mail">
        <mat-header-cell *matHeaderCellDef mat-sort-header> E-mail </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.mail }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="telefono">
        <mat-header-cell *matHeaderCellDef mat-sort-header> telefono </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.telefono }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="direccion">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Dirección </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.direccion }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contacto">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Contacto </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.contacto }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="telefono_contacto">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Telefono Contacto </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.telefono_contacto }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="estado">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Activo </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox color="primary" [(ngModel)]="row.estado" (change)="changeStatusCompany(row)"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ... </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button [routerLink]="['./', row.id]">
            <mat-icon>remove_red_eye</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card>


  <div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner ></mat-spinner>
  </div>
</div>
