<div class="padding-box">
  <div class="panel-buttons" *ngIf="user.rol !== 2">
    <h3 class="line-title">Pagos</h3>
    <div class="spacer"></div>
    <button
      style="background: #e60000;"
      *ngIf="!viewPreview"
      mat-fab
      (click)="selectFileCurrencyLoad()"
      matTooltip="Subir archivo"
      matTooltipPosition="below"
    >
        <mat-icon>cloud_upload</mat-icon>
    </button>
  </div>

  <mat-card style="overflow:auto; margin-bottom: 50px;" *ngIf="!viewPreview; else templatePreview">
    <mat-progress-bar *ngIf="loading && progres > 0" [value]="progres" mode="buffer"></mat-progress-bar>

    <mat-table *ngIf="displayedColumns.length > 0" [dataSource]="dataSource" matSort [matSortActive]="sortBy" [matSortDirection]="sortDir" matSortDisableClear
               (matSortChange)="resetIndexAndLoadData()">

      <ng-container matColumnDef="company">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Empresa </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.nombre_empresa }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="files">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Archivo </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.nombre }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="currency">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Moneda </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.descripcion }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Monto </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{  tool.formatDisplay(row.monto, 2,3, '.', ',', '') }} {{ row.iso }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date_load">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Carga </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.fecha_carga | date: 'YYYY-MM-dd h:mm:ss' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date_update">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Modificación </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.fecha_modificacion | date: 'YYYY-MM-dd h:mm:ss' }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Estado </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-chip
                    matTooltip="Cambiar estado"
                    matTooltipPosition="below"
                    (click)="changeStatus(row)"
                    [ngClass]="{'verify': row.estadoFile == 2, 'deferred': row.estadoFile == 1, 'rejected': row.estadoFile == 3}"
                    color="primary"
                    [disabled]="row.estadoFile == 3 || row.estadoFile == 2">
            {{ getStatus(row.estadoFile) }}
          </mat-chip>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="acciones">
        <mat-header-cell *matHeaderCellDef mat-sort-header> ... </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="viewDetailFile(row)"
                  matTooltip="Ver detalle"
                  matTooltipPosition="below"
          >
            <mat-icon style="color: #276f4e;">open_in_new</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card>

  <ng-template #templatePreview>
    <app-preview (close)="eventPreview($event)" [onlyView]="onlyView" [fileId]="fileId" [estado]="contentPreview?.estado"  [companyId]="companyId" [currency]="contentPreview.currency" [data]="contentPreview.fileContent" [fileName]="contentPreview.fileName"></app-preview>
  </ng-template>


  <div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner></mat-spinner>
  </div>
</div>
