<ng-container *ngIf="sesion.getUser(); then notauthenticated; else authenticated">
</ng-container>

<ng-template #notauthenticated>
  <app-app-layout-authenticated></app-app-layout-authenticated>
</ng-template>

<ng-template #authenticated>
  <router-outlet></router-outlet>
</ng-template>
