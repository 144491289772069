<div class="padding-box">
  <div class="panel-buttons">
    <h3 class="line-title">Movimiento de Cuentas</h3>
    <div class="spacer"></div>
    <button [disabled]="dataSource.data.length == 0" (click)="generatePDF()" style="background: #e60000; margin-right: 10px;" mat-fab><mat-icon>cloud_download</mat-icon></button>
  </div>


  <mat-expansion-panel style="margin-bottom: 15px; margin-top: 10px;" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtros
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form *ngIf="form" [formGroup]="form" autocomplete="off">
      <div class="panel-row">
        <div class="panel-fields">
          <mat-form-field>
            <mat-select placeholder="Moneda" formControlName="money">
              <mat-option *ngFor="let opt of currencies" [value]="opt">{{ opt.descripcion }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-select placeholder="Tienda" formControlName="store">
              <mat-option *ngFor="let opt of stores" [value]="opt">{{ opt.descripcion }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
              <input readonly matStartDate formControlName="dateStart" placeholder="Fecha Inicio">
              <input readonly matEndDate formControlName="dateEnd" placeholder="Fecha Final">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div style="float: right;">
      <button style="margin-right: 10px;" (click)="clearValues();" mat-flat-button>Limpiar</button>
      <button style="color: white; background: #e60000; margin-right: 10px;" (click)="filters()" [disabled]="!form.valid" mat-flat-button>aplicar</button>
    </div>
  </mat-expansion-panel>

  <mat-card style="overflow:auto; margin-bottom: 50px;">
    <mat-table *ngIf="displayedColumns.length > 0" [dataSource]="dataSource" matSort [matSortActive]="sortBy" [matSortDirection]="sortDir" matSortDisableClear
      (matSortChange)="resetIndexAndLoadData()">

      <ng-container matColumnDef="fecha">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.fecha }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="descripcion">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tienda </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.descripcion }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caja">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Caja </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.caja }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nro_fact">
        <mat-header-cell *matHeaderCellDef mat-sort-header> N° Factura </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.nro_fact }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="descriptrans">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.descriptrans }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="debito">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Debito </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ tools.formatDisplay(row.debito, 3, 3, '.', ',' ) }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="credito">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Credito </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ tools.formatDisplay(row.credito, 3, 3, '.', ',' ) }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-card>


  <div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner ></mat-spinner>
  </div>
</div>
