import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api/api.service';
import {SesionService} from '../services/sesion/sesion.service';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  form: FormGroup;
  loading: any = false;

  constructor(public apiService: ApiService, public sesion: SesionService, public router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      isCompany: new FormControl(false)
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      this.loading = true;
      this.apiService.recovery(this.form.value).subscribe( (res: any) => {
        this.loading = false;
        if (res?.code === 200) {
          this.snackBar.open(res?.message ? res.message : 'Solicitud enviada con exito.', 'ok', { duration: 5000 });
        }  else if (res?.code === 204) {
          this.snackBar.open(res?.message ? res.message : 'El correo ingresado no existe.', 'ok', { duration: 5000 });
        } else {
          this.snackBar.open(res?.message ? res.message : 'No se logro procesar la solicitud.', 'ok', { duration: 5000 });
        }
      }, () => {
        this.loading = false;
        this.snackBar.open('No se logro procesar la solicitud.', 'ok', { duration: 5000 });
      });
    }
  }
}
