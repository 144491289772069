<div class="padding-box">
  <div class="panel-buttons">
    <h3 class="line-title">Estado de Cuentas</h3>
    <div class="spacer"></div>
    <button (click)="generatePDF()" [disabled]="dataSource.data.length == 0" style="background: #e60000; margin-right: 10px;" mat-fab><mat-icon>cloud_download</mat-icon></button>
  </div>

  <mat-card #containerTable style="overflow:auto; margin-bottom: 50px;">
    <mat-table *ngIf="displayedColumns.length > 0" [dataSource]="dataSource" matSort [matSortActive]="sortBy" [matSortDirection]="sortDir" matSortDisableClear
      (matSortChange)="resetIndexAndLoadData()">

      <ng-container matColumnDef="divisa">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Divisa </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.descripcion }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="iso">
        <mat-header-cell *matHeaderCellDef mat-sort-header> COD </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.iso }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="monto">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Monto </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ tools.formatDisplay(row.monto, 3, 3, '.', ',' ) }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

      <!-- <mat-paginator
        [pageSizeOptions]="[10,25,50,100]"
        [pageIndex]="pageIndex"
        [pageSize]="pageSize"
        showFirstLastButtons
        (page)="loadData()">
      </mat-paginator> -->
  </mat-card>

  <div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner ></mat-spinner>
  </div>
</div>
