import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SesionService } from 'src/app/services/sesion/sesion.service';
import { DialogGenComponent } from 'src/app/dialog-gen/dialog-gen.component';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-app-layout-authenticated',
  templateUrl: './app-layout-authenticated.component.html',
  styleUrls: ['./app-layout-authenticated.component.scss']
})
export class AppLayoutAuthenticatedComponent implements OnInit {
  openMenu: any = false;
  user: any;
  options: any = [];

  constructor(private router: Router, private sesion: SesionService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.user = this.sesion.getUser();
    this.buildMenu();
    this.checkSesion();
  }

  public buildMenu(): void {
    const opt = this.user.permissions.split(',');
    if (this.user.rol === 2) {
      for (let item of opt) {
        if (item === '1') {
          this.options.push({icon: 'account_balance', title: 'Estado de Cuenta', action: '/account-status'});
        }

        if (item === '2') {
          this.options.push({icon: 'account_balance_wallet', title: 'Movimientos de Cuentas', action: '/account-movements'});
        }

        if (item === '3') {
          this.options.push({icon: 'content_paste', title: 'Resúmen de Clientes', action: '/customers'});
        }

        if (item === '4') {
          this.options.push({icon: 'people_alt', title: 'Movimientos de Cliente', action: '/customers-movements'});
        }

        if (item === '5') {
          this.options.push({icon: 'business', title: 'Compañias', action: '/companies'});
        }
      }
    } else if (this.user.rol === 3) {
      this.options.push({icon: 'person', title: 'Perfil', action: '/account'});
      this.options.push({icon: 'payment', title: 'Pagos', action: '/payments'});
    } else if (this.user.rol === 4) {
      this.options.push({icon: 'payment', title: 'Pagos', action: '/panel'});
    }
  }

  public checkSesion(): void {
    const date = this.sesion.getKeyTime();
    console.log('checkSesion Life');

    if (this.sesion.getUser()) {
      if (date) {
        let now = moment();
        let dateLogin = moment(date);
        var duration = moment.duration(now.diff(dateLogin));
        var minutes = duration.asMinutes();
        console.log('minutes :::::', minutes);
        if (minutes > (this.user.rol === 4 ? 240 : 120)) {
          this.openDialog();
        }
      } else {
        this.openDialog();
      }
    }
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(DialogGenComponent,
    {
      width: '250px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sesion.clearUser();
        this.router.navigate(['login']);
      }
    });
  }

  public logout(): void {
    this.sesion.clearUser();
    this.router.navigate(['login']);
  }

  public actionMenu() {}

}
