import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AccountStatusComponent } from 'src/app/account-status/account-status.component';
import { AccountMovementsComponent } from 'src/app/account-movements/account-movements.component';
import { CustomersComponent } from 'src/app/customers/customers.component';
import { CustomersMovementsComponent } from 'src/app/customers-movements/customers-movements.component';
import { CompaniesComponent } from './companies/companies.component';

import { ViaAuthGuard } from 'src/app/guards/via-auth.guard';
import {EditCompanyComponent} from './edit-company/edit-company.component';
import {CompanyFilesComponent} from './company-files/company-files.component';
import {PaymentPanelComponent} from './payment-panel/payment-panel.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';

const appRoutes = [
  { path: 'account-status', component: AccountStatusComponent, canActivate: [ViaAuthGuard] },
  { path: 'account-movements', component: AccountMovementsComponent, canActivate: [ViaAuthGuard] },
  { path: 'customers', component: CustomersComponent, canActivate: [ViaAuthGuard] },
  { path: 'customers-movements', component: CustomersMovementsComponent, canActivate: [ViaAuthGuard] },
  { path: 'companies', component: CompaniesComponent, canActivate: [ViaAuthGuard] },
  { path: 'companies/:id', component: EditCompanyComponent, canActivate: [ViaAuthGuard] },
  { path: 'account', component: EditCompanyComponent, canActivate: [ViaAuthGuard] },
  { path: 'company/new', component: EditCompanyComponent, canActivate: [ViaAuthGuard] },
  { path: 'payments', component: CompanyFilesComponent, canActivate: [ViaAuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'panel', component: PaymentPanelComponent },
  { path: '**', redirectTo: 'account-status', pathMatch: 'full' }
];

export const RoutingApp = RouterModule.forRoot(appRoutes, { useHash: true });
