<div class="login-container">
  <div class="login" fxLayout="column" alignment="center" fxLayoutGap="20px">
    <div style="width: 100%; min-width: 400px; margin-bottom: 1em;">
      <img style="width: 50%; max-width: 300px;" src="assets/imgs/logo-montes.png">
    </div>
    <div fxLayout fxLayoutGap="20px" fxLayoutAlign="center center"></div>
    <form fxLayout="column" fxLayoutGap="10px" (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
      <a [routerLink]="['../login']" style="cursor: pointer;"><mat-icon style="color: white;" class="mat-18">arrow_back</mat-icon></a>
      <br>
      <p>
        Introduzca la dirección de correo electrónico asociada con su cuenta y le mandaremos un email con instrucciones para establecer una nueva contraseña.
      </p>
      <mat-form-field appearance="outline" color="primary">
        <input type="email" matInput placeholder="Correo" formControlName="email">
        <mat-error>Email Requerida</mat-error>
      </mat-form-field>
      <br>
      <mat-checkbox style="color: white; margin-bottom: 0.5em;" formControlName="isCompany">Tengo cuenta de empresa</mat-checkbox>

      <div fxLayout fxLayoutGap="20px" style="justify-content: center; display: grid;">
        <mat-spinner *ngIf="loading;else loginButton"></mat-spinner>
        <ng-template #loginButton>
          <button class="btn-in-rsv" [disabled]="!form.valid" mat-flat-button fxFlex> Enviar instrucciones</button>
        </ng-template>
      </div>
    </form>
    <div class="spacer"></div>
  </div>
</div>
