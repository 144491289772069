import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-gen',
  templateUrl: './dialog-gen.component.html',
  styleUrls: ['./dialog-gen.component.css']
})
export class DialogGenComponent implements OnInit {
  title: any = 'Información';
  message: any = 'Su sesión expiró.';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data?.title) {
      this.title = data.title;
    }

    if (data?.message) {
      this.message = data.message;
    }
  }

  ngOnInit(): void {
  }

}
