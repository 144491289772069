import { Component, OnInit, Input } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api/api.service';
import {Router, ActivatedRoute} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SesionService } from 'src/app/services/sesion/sesion.service';
import { ToolsService } from '../services/tools/tools.service';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent implements OnInit {
  form: FormGroup;
  company: any;
  currenciesSelected: any = [];
  editMode: any = false;
  loading: any = false;
  currencies: any = [];
  edit: any = false;
  user: any;
  companyId: any;
  parentPhone: any = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$';
  reload: any = false;

  constructor(
    public api: ApiService,
    public router: Router,
    public snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private sesion: SesionService,
    public tool: ToolsService
  ) { }

  ngOnInit(): void {
    this.user = this.sesion.getUser();
    console.log('::::', this.user);
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.id !== 'new' && (params.id || this.user.rol === 3)) {
        this.companyId = params.id || this.user.id;
        console.log('::::::', this.companyId);
        this.editMode = true;
        this.loading = true;
        this.api.getOneCompany(this.companyId).subscribe((company: any) => {
          this.loading = false;
          if (company.code === 200) {
            this.company = company.company[0];
            this.currenciesSelected = company.currencies;
            this.initilize();
            this.getCurrencies();
            console.log(this.company);
          }
        }, (err: any) => {
          this.loading = false;
        });
      } else {
        this.getCurrencies();
        this.initilize();
      }
    });
  }

  public checkoutMoneySelect(): any {
    return this.currencies.filter((elem: any) => elem.estado === true );
  }

  private getCurrencies(): void {
    this.loading = true;
    this.api.getCurrencies().subscribe((currencies: any) => {
      this.loading = false;
      if (currencies.code === 200) {
        const array = [];
        for (const item of currencies.info.currency) {
          let state = false;
          if (this.editMode) {
            state = this.currenciesSelected.find(elem => elem.moneyCod === item.codigo);
            if (state) {
              state = state['estado'] === 1 ? true : false;
            }
          }

          array.push({
            codigo: item.codigo,
            description: item.descripcion,
            iso: item.iso,
            factor: item.factor,
            estado: state
          });
        }
        this.currencies = array;
      }
    }, (err) => {
      this.loading = false;
      console.log(err);
    });
  }

  private initilize(): void {
    this.form = new FormGroup({
      typeRif: new FormControl(this.editMode ? this.company.rif[0] : 'J', [Validators.required]),
      rif: new FormControl(this.editMode ? this.company.rif.slice(1) : null, [Validators.required]),
      name: new FormControl(this.editMode ? this.company.nombre_empresa : null, [Validators.required]),
      email: new FormControl(this.editMode ? this.company.mail : null, [Validators.required, Validators.email]),
      phone: new FormControl(this.editMode ? this.company.telefono : null, [
        Validators.required, Validators.pattern(this.parentPhone), Validators.maxLength(15)
      ]),
      direction: new FormControl(this.editMode ? this.company.direccion : null, [Validators.required]),
      password: new FormControl(this.editMode ? 'xxxxxxxx' : null, [Validators.required, Validators.minLength(4)]),
      name_contact: new FormControl(this.editMode ? this.company.contacto : null, [Validators.required]),
      phone_contact: new FormControl(this.editMode ? this.company.telefono_contacto : null, [
        Validators.required, Validators.pattern(this.parentPhone), Validators.maxLength(15)
      ]),
      estado: new FormControl(this.editMode ? this.company.estado : 1)
    });

    if (this.editMode) {
      this.enablesInputs(false);
    }
  }

  enablesInputs(value): any {
    if (value) {
      if (this.user.rol !== 3) {
        this.form.get('typeRif').enable();
        this.form.get('rif').enable();
      }
      this.form.get('name').enable();
      this.form.get('email').enable();
      this.form.get('phone').enable();
      this.form.get('direction').enable();
      // this.form.get('password').enable();
      this.form.get('name_contact').enable();
      this.form.get('phone_contact').enable();
      this.form.get('estado').enable();
      this.edit = true;
    } else {
      this.form.get('typeRif').disable();
      this.form.get('rif').disable();
      this.form.get('name').disable();
      this.form.get('email').disable();
      this.form.get('phone').disable();
      this.form.get('direction').disable();
      this.form.get('password').disable();
      this.form.get('name_contact').disable();
      this.form.get('phone_contact').disable();
      this.form.get('estado').disable();
      this.edit = false;
    }
  }

  callSubmit(): void {
    if (this.edit) {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    const values = this.form.value;
    this.loading = true;
    let id = null;

    if (this.editMode) {
      id = this.company?.id || this.user.id;
    }

    this.api.createCompany({
      rif: this.company?.id ? this.company.rif : `${values.typeRif}${values.rif}`,
      nombre_empresa: values.name,
      mail: values.email,
      password: values.password,
      telefono: values.phone,
      direccion: values.direction,
      contacto: values.name_contact,
      telefono_contacto: values.phone_contact,
      estado: values.estado,
      currencies: this.currencies
    }, id).subscribe((response: any) => {
      this.loading = false;
      this.reload = true;
      if (response?.status === 'error') {
        this.snackBar.open(response?.message ? response?.message : 'Error inesperado creando empresa', 'ok', { duration: 5000 });
      } else if (response === 1002) {
        this.snackBar.open(`No se ${this.company?.id ? 'Actualizo' : 'Creo' } la empresa.`, 'ok', { duration: 5000 });
      } else {
        this.snackBar.open(`Se ${this.company?.id ? 'Actualizo' : 'Creo' } la empresa exitosamente.`, 'ok', { duration: 5000 });

        if (!this.company?.id) {
          this.router.navigate(['../companies'], {replaceUrl: true});
        } else {
          window.location.reload();
        }
      }
    }, (err: any) => {
      this.loading = false;
      console.log(err);
      this.snackBar.open(err?.error?.message ? err?.error?.message : 'Error inesperado creando empresa', 'ok', { duration: 5000 });
    });
  }


}
