import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SesionService } from 'src/app/services/sesion/sesion.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ViaAuthGuard implements CanActivate {
  constructor(private sesion: SesionService, private router: Router) { }

  canActivate(): any {
    const user = this.sesion.getUser();
    if (!user) {
      this.router.navigate(['/login']);
      return false;
    }
    /*if (user && user.rol === 3) {
      this.router.navigate(['/account']);
      return false;
    }*/
    return true;
  }

}
