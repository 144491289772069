import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// ------------------------------------------/
// ------------------------------------------/
// --------------- Components -----------------/
// ------------------------------------------/
// ------------------------------------------/
import { LoginComponent } from './login/login.component';
import { AppLayoutAuthenticatedComponent } from './app-layout-authenticated/app-layout-authenticated.component';

// ------------------------------------------------------------ //
// --------------------------Router---------------------------- //
// ------------------------------------------------------------ //
import { RoutingApp } from './app.routing';
import { AccountStatusComponent } from './account-status/account-status.component';
import { CustomersComponent } from './customers/customers.component';
import { AccountMovementsComponent } from './account-movements/account-movements.component';
import { CustomersMovementsComponent } from './customers-movements/customers-movements.component';
import { DialogGenComponent } from './dialog-gen/dialog-gen.component';
import { CompaniesComponent } from './companies/companies.component';
import { EditCompanyComponent } from './edit-company/edit-company.component';
import { CompanyFilesComponent } from './company-files/company-files.component';
import { LoadModalComponent } from './load-modal/load-modal.component';
import { PreviewComponent } from './preview/preview.component';
import { ChangeStatusComponent } from './change-status/change-status.component';
import { PaymentPanelComponent } from './payment-panel/payment-panel.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppLayoutAuthenticatedComponent,
    AccountStatusComponent,
    CustomersComponent,
    AccountMovementsComponent,
    CustomersMovementsComponent,
    DialogGenComponent,
    CompaniesComponent,
    EditCompanyComponent,
    CompanyFilesComponent,
    LoadModalComponent,
    PreviewComponent,
    ChangeStatusComponent,
    PaymentPanelComponent,
    PasswordResetComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RoutingApp,
    HttpClientModule
  ],
  exports: [MaterialModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
