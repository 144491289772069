<div class="padding-box">
  <mat-accordion displayMode="default">
    <mat-expansion-panel expanded="true" [hideToggle]="false">
      <mat-expansion-panel-header style="padding: 0.5em 2em; background: #f3f2f2;">
        <mat-panel-title>
          <div>
            <div>
              <label><b>Nombre del archivo: </b> {{ fileName }} </label>
            </div>

            <div>
              <label><b>Moneda: </b> {{ currency?.descripcion }}</label> &nbsp;
              <label><b>Total: </b> {{ tool.formatDisplay(total,2,3, '.', ',', '') }}</label> &nbsp;
              <label><b>Personas: </b> {{ dataSource.data.length  }} </label> &nbsp;
              <label *ngIf="newUsers > 0"><b>Usuarios nuevos: </b> {{ newUsers  }} </label>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-panel-description>
        <div class="aling-right" style="width: 100%; padding-top: 1em;">
          <div *ngIf="loading" style="display: inline-block; text-align: center; padding-right: 1em;">
            Este proceso puede <br> llevar algunos minutos...
          </div>
          <button *ngIf="onlyView" mat-stroked-button class="content" (click)="changeStatus()" matTooltip="Cambiar estado" matTooltipPosition="below" [ngClass]="{'verify-file': estado == 2, 'deferred': estado == 1, 'rejected': estado == 3}" [disabled]="estado == 3" color="primary">
            <div style="display: inline-flex"> <mat-spinner *ngIf="loading" style="position: relative;top: 0.5em;margin-right: 0.5em;" [diameter]="20"></mat-spinner>{{ getStatus(estado) }}</div>
          </button>
          &nbsp;
          <button mat-stroked-button (click)="emitBack()" matTooltip="{{ successProcess ? 'Volver' : 'Cancelar' }}" matTooltipPosition="below">
            <div style="display: inline-flex"> {{ successProcess ? 'Volver' : 'Cancelar' }}</div>
          </button>
          &nbsp;
          <button *ngIf="!onlyView" mat-stroked-button class="border-verify" [disabled]="successProcess || repeatUsers || conflictUsers" (click)="loadFile()" matTooltip="Procesar archivo"  matTooltipPosition="below">
            <div style="display: inline-flex"> <mat-spinner *ngIf="loading" style="position: relative;top: 0.5em;margin-right: 0.5em;" [diameter]="20"></mat-spinner>Procesar</div>
          </button>
        </div>
      </mat-panel-description>

      <mat-card style="overflow:auto; margin-bottom: 2em; margin-top: 2em;">
        <mat-progress-bar *ngIf="loading && progres > 0" [value]="progres" mode="buffer"></mat-progress-bar>
        <mat-table *ngIf="displayedColumns.length > 0" [dataSource]="dataSource">

          <ng-container matColumnDef="nombre">
            <mat-header-cell *matHeaderCellDef> Nombre </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.nombre }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="cedula">
            <mat-header-cell *matHeaderCellDef> Cédula </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.cedula }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> Email </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.email }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="monto">
            <mat-header-cell *matHeaderCellDef> Monto </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{  tool.formatDisplay(row.monto, 2,3, '.', ',', '') }} {{ currency.iso }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="estado">
            <mat-header-cell *matHeaderCellDef> Estado </mat-header-cell>
            <mat-cell *matCellDef="let row"> <mat-chip [ngClass]="{'repeat': row?.repeat, 'withoutData': row?.withoutData, 'verify': row.estado == 1, 'not-exits': row.estado == 0}" color="primary"> {{ getNameStatus(row.estado, row?.repeat, row?.withoutData) }} </mat-chip> </mat-cell>
          </ng-container>

          <!--
          <ng-container matColumnDef="acciones">
            <mat-header-cell *matHeaderCellDef> ... </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button [routerLink]="['./', row.id]">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          -->
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
      </mat-card>
    </mat-expansion-panel>
  </mat-accordion>
</div>
