<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over">
    <mat-toolbar>
        <button
          type="button"
          mat-icon-button
          (click)="sidenav.close()">
          <mat-icon aria-label="Side nav toggle icon">close</mat-icon>
        </button>
    </mat-toolbar>


    <mat-nav-list>
      <mat-list-item matLine>
        <h4 matLine> <mat-icon class="position-icon">person</mat-icon> <b>{{ user?.name }}</b></h4>
      </mat-list-item>
      <mat-divider class="button-header-desktop"></mat-divider>
      <br>
      <mat-list-item matLine *ngFor="let opt of options" [routerLink]="opt.action" (click)="sidenav.close()">
        <h4 matLine> <mat-icon class="position-icon">{{opt.icon}}</mat-icon> {{opt.title}} </h4>
      </mat-list-item>
      <mat-list-item matLine (click)="logout(); sidenav.close()">
        <h4 matLine><mat-icon class="position-icon">exit_to_app</mat-icon> Cerrar Sesión </h4>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary" class="main-navbar mat-elevation-z6">
      <!-- <mat-toolbar-row>
        <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
          <mat-icon>menu</mat-icon>
        </button>
        <span>Garzón</span>
        <span class="spacer"></span>
        <button class="button-header-desktop" mat-button><mat-icon aria-label="Side nav toggle icon">account_circle</mat-icon> Mi cuenta</button>
      </mat-toolbar-row> -->
      <mat-toolbar-row>
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="sidenav.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>

        <span class="spacer"></span>
        <mat-divider class="button-header-desktop" [vertical]="true"></mat-divider>
        <button class="button-header-desktop" mat-button [matMenuTriggerFor]="menu"><mat-icon aria-label="Side nav toggle icon">account_circle</mat-icon> Mi cuenta</button>
        <mat-menu #menu="matMenu">
          <button (click)="logout()" mat-menu-item>Cerrar Sesión</button>
        </mat-menu>

      </mat-toolbar-row>

      <!-- <button
        (click)="actionMenu()"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button class="button-header-mobile">
        <mat-icon *ngIf="!openMenu" aria-label="Side nav toggle icon">more_vertical</mat-icon>
        <mat-icon *ngIf="openMenu" aria-label="Side nav toggle icon">close</mat-icon>
      </button> -->
    </mat-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
