import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ApiService} from 'src/app/services/api/api.service';
import {ToolsService} from 'src/app/services/tools/tools.service';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {GeneratePdfService} from 'src/app/services/pdf/generate-pdf.service';
import * as moment from 'moment';
import {MatPaginator} from '@angular/material/paginator';
import {Router} from "@angular/router";

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: any;

  form: FormGroup;

  pageIndex = '0';
  pageSize = '10';
  sortBy = 'vw.updated_at';
  sortDir = 'desc';
  loading: any = false;
  currenciesOptions: Set<string> = new Set<string>();
  currencies: Set<string> = new Set<string>();
  totalRecords = 0;
  options: any = [];

  constructor(
    public apiService: ApiService,
    public tools: ToolsService,
    public pdfService: GeneratePdfService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.getCurrenciesInfo();
    this.getClients();
  }

  getClients(): any {
    //const values = this.form.value;
    const values = this.form.getRawValue();
    const dateStart = values.dateStart && values.dateStart.toString().length ? moment(values.dateStart).format('YYYY-MM-DD'): null;
    const dateEnd = values.dateEnd && values.dateEnd.toString().length ? moment(values.dateEnd).format('YYYY-MM-DD'): null;
    const client = values.client ? values.client.name : null;
    const currency = values.currency;
    this.loading = true;
    this.apiService
      .getSummary({
        dateStart,
        dateEnd,
        client,
        currency,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDir: this.sortDir
      })
      .subscribe({
        next: ({code, data}) => {
          this.loading = false;
          if (code === 200) {
            const array: any = [];
            if (data.rows.length === 0) {
              this.displayedColumns = [];
              this.dataSource.data = [];
              this.totalRecords = 0;
              return;
            }

            for (const item of data.rows) {
              this.currencies = new Set<string>(item.currencies.map(e => e.iso));
              array.push({
                id_number: item.id_number,
                name: item.name,
                client_id: item.client_id,
                ...item.currencies.reduce((acc, curr) => {
                  acc[curr.iso] = curr.monto;
                  return acc;
                }, {})
              });
            }

            this.displayedColumns = ['id_number', 'name', ...this.currencies, 'options'];
            this.totalRecords = data.count;
            this.dataSource.data = array;
            setTimeout(() => {
              this.resetIndexAndLoadData();
            }, 500);
          }
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });

  }

  getCurrenciesInfo(): void {
    this.apiService.getCurrencies()
      .subscribe({
        next: ({code, info}) => {
          if (code === 200) {
            this.currenciesOptions.clear();
            this.currenciesOptions.add('TODAS');
            info.currency.map(e => this.currenciesOptions.add(e.iso));
          }
        },
        error: () => {
          this.currenciesOptions.clear();
          this.currenciesOptions.add('TODAS');
        }
      });
  }


  initForm() {
    this.form = new FormGroup({
      dateStart: new FormControl(""),
      dateEnd: new FormControl(""),
      client: new FormControl(null, []),
      currency: new FormControl('TODAS', [])
    });
  }

  generatePDF() {
    this.pdfService.autoTable(
      ['Cédula', 'Nombre', 'USD', 'COP', 'BS'],
      this.generateInfo(this.dataSource.data),
      'Resúmen de clientes',
      `Resúmen de clientes ${moment().format('YYYY-MM-DD Hms')}`,
      false,
      []
    );
  }

  generateInfo(data) {
    const array = [];
    for (const item of data) {
      const info = [
        item.id_number,
        item.name,
        this.tools.formatDisplay(item.USD, 3, 3, '.', ',', ''),
        this.tools.formatDisplay(item.COP, 3, 3, '.', ',', '$'),
        this.tools.formatDisplay(item.PTS || 0, 3, 3, '.', ',', '')
      ];
      array.push(info);
    }
    return array;
  }

  resetIndexAndLoadData() {
    if (this.sort) {
      this.dataSource.data = this.tools.orderList(this.dataSource.data, this.sort.active, this.sort.direction);
    }
  }

  onChangePaginator() {
    this.pageIndex = this.paginator.pageIndex;
    this.pageSize = this.paginator.pageSize;
    this.getClients();
  }


  onViewMovements(row: {id_number: string, name: string, client_id: number} & Record<string, number>) {
    const url = `#/customers-movements?id_number=${row.id_number}&name=${row.name}&client_id=${row.client_id}&dateStart=${moment(this.form.value.dateStart).format('YYYY-MM-DD')}&dateEnd=${moment(this.form.value.dateEnd).format('YYYY-MM-DD')}`;
    window.open(url, '_blank');
  }

  filterClients() {
    const value = this.form.get('client').value;
    if (value && value.length > 1) {
      this.apiService
        .getclients(this.form.get('client').value)
        .subscribe({
          next: (response: any) => {
            if (response.code === 200) {
              this.options = response.clients;
            }
          }
        });
    } else {
      this.options = [];
    }
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.options
      .filter(
        option => option.name.toLowerCase().indexOf(filterValue) === 0
          || option.id_number.toLowerCase().indexOf(filterValue) !== -1
          || option.email.toLowerCase().indexOf(filterValue) === 0
      );
  }

  focusout($event: FocusEvent) {
    if (typeof (this.form.get('client').value) === 'string' && this.form.get('client').value && this.form.get('client').value.length > 1) {
      const array = this._filter(this.form.get('client').value);
      if (array.length > 0) {
        this.form.get('client').setValue(array[0]);
      }
    }
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }
}
