<div class="login-container">
  <div class="login" fxLayout="column" alignment="center" fxLayoutGap="20px">
    <div style="width: 100%; min-width: 400px; margin-bottom: 1em;">
      <img style="width: 50%; max-width: 300px;" src="assets/imgs/logo-montes.png">
    </div>
    <div fxLayout fxLayoutGap="20px" fxLayoutAlign="center center"></div>
    <form fxLayout="column" fxLayoutGap="10px" (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="form">
      <h2 class="title">Acceso</h2>
      <mat-form-field appearance="outline" color="primary">
        <input type="email" matInput placeholder="Correo" formControlName="username">
        <mat-error>Email Requerida</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline" color="primary">
        <input type="password" matInput placeholder="Contraseña" formControlName="password">
        <mat-error>Contraseña Requerida</mat-error>
      </mat-form-field>

      <br>
      <mat-checkbox style="color: white; margin-bottom: 1em;" formControlName="isCompany">Tengo cuenta de empresa</mat-checkbox>

      <div fxLayout fxLayoutGap="20px" style="justify-content: center; display: grid;">
        <mat-spinner *ngIf="loading;else loginButton"></mat-spinner>
        <ng-template #loginButton>
          <button class="btn-in-rsv" mat-flat-button fxFlex> Ingresar </button>
        </ng-template>
      </div>
      <div fxLayout fxLayoutGap="20px" fxLayoutAlign="center center" style="margin-top: 1em;">
        Establecer un nueva contraseña <a  [routerLink]="['../password-reset']" style="color: white;">Haga click aqui</a>
      </div>
    </form>
    <div class="spacer"></div>
  </div>
</div>
