<mat-toolbar>
  <span>Verificación de usuarios</span>
</mat-toolbar>



<mat-card style="margin: 2em;">
  <mat-card-header>
    <mat-card-title>Datos del cliente</mat-card-title>
    <mat-card-subtitle>
      Tiempo de sesión
      <span style="color: #e60000; font-weight: bold;">{{ timeBuy }}</span>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form *ngIf="form" [formGroup]="form" autocomplete="off">
      <div class="box">
        <div style="width: 10%; margin: 0.5em;">
          <mat-form-field style="width: 100%;" appearance="outline">
            <mat-select placeholder="Tipo" formControlName="typeRif">
              <mat-option value="CC">CC</mat-option>
              <mat-option value="CE">CE</mat-option>
              <mat-option value="PAS">PAS</mat-option>
              <mat-option value="NIT">NIT</mat-option>
            </mat-select>
            <mat-error>El tipo es requerido</mat-error>
          </mat-form-field>
        </div>
        <div style="width: 30%; margin: 0.5em;">
          <mat-form-field floatLabel="auto" style="width: 100%;" appearance="outline">
            <mat-label>RIF</mat-label>
            <input matInput placeholder="Ingrese el Rif" formControlName="rif" (keypress)="tool.numberOnly($event)">
            <mat-error>El Rif es requerido</mat-error>
          </mat-form-field>
        </div>
        <div style="margin-top: 1.3em;">
          <button class="btn-in-rsv" mat-raised-button (click)="searchClient()" [disabled]="!form.valid">Buscar</button>
          &nbsp;
          <button class="btn-in-reset" (click)="reset()" mat-stroked-button [disabled]="!form.valid">Reinicar</button>
        </div>
      </div>
    </form>

    <mat-divider></mat-divider>
    <div *ngIf="wallets?.length > 0">
      <span style="font-width: 600;">Para poder generar el token de acceso ingresa tu clave teléfonica.</span>
      <mat-form-field floatLabel="auto" style="width: 10%; margin-left: 1em; margin-top: 1em;" appearance="outline">
        <mat-label>Clave Teléfonica</mat-label>
        <input matInput [(ngModel)]="key" type="password" autocomplete="off" (keypress)="tool.numberOnly($event)">
      </mat-form-field>
    </div>

    <div style="margin-top: 1em;">
      <mat-label>Monederos</mat-label>
      <div class="cardList">
        <mat-card class="cardListItem" style="margin-top: 1em; width: 25% !important;" *ngFor="let wallet of wallets">
          <mat-card-header>
            <mat-card-title>
              {{ wallet.descripcion }}
              <mat-divider></mat-divider>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content style="width: 100%; text-align: right;">
            <mat-label> {{ wallet.name }} </mat-label>
            <br>
            <mat-label style=" font-size: 2em; color: #276f4e;">{{ tool.formatDisplay(wallet.monto, 2,3, '.', ',', '') }} {{ wallet.iso }}</mat-label>
          </mat-card-content>
          <mat-card-actions align="right">
            <button class="btn-in-rsv" mat-raised-button (click)="generateToken(wallet)" [disabled]="wallet.monto <= 0 || !key">Generar TOKEN</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
  <mat-spinner ></mat-spinner>
</div>
