import { Component } from '@angular/core';
import { SesionService } from 'src/app/services/sesion/sesion.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dashboard-montes';

  constructor(public sesion: SesionService) {}
}
