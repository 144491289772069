import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api/api.service';
import { SesionService } from 'src/app/services/sesion/sesion.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  loading: any = false;

  error_messages: any = {
    username: [
      { type: 'required', message: 'El usuario es requerido'},
      { type: 'minlength', message: 'El usuario debe ser mayor o igual a 6 caracteres.'},
      { type: 'maxlength', message: 'El usuario no puede superar los 50 caracteres'},
      { type: 'pattern', message: 'El formato del email no es válido'},
      { type: 'email', message: 'El Email no es válido'}
    ],
    password: [
      { type: 'required', message: 'La contraseña es requerida'},
      { type: 'minlength', message: 'La contraseña deber mayor o igual a 6 caracteres'},
      { type: 'maxlength', message: 'La contraseña no de superar los 10 caracteres'},
      { type: 'pattern', message: 'La contraseña debe contener números, letras mayúsculas y minúsculas'}
    ]
  };

  constructor(public apiService: ApiService, public sesion: SesionService, public router: Router, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      isCompany: new FormControl(false)
    });
  }

  public async onSubmit() {
    if (this.form.valid) {
      this.loading =  true;
      this.apiService.authUser(
        this.form.value.username,
        this.form.value.password,
        this.form.value.isCompany
      ).subscribe(async (res) => {
        console.log(res);
        if (res?.code === 200) {
          let decode: any = this.sesion.decodeToken(res.data);
          if ([2, 3].includes(decode?.rol)) {
            decode.token = res?.data;
            this.sesion.saveUser(decode);
            this.sesion.user = decode;
            this.sesion.setDate();
            if ( decode.rol === 2) {
              await this.router.navigate(['account-status'], {replaceUrl: true});
            } else if (decode.rol === 3) {
              await this.router.navigate(['account'], {replaceUrl: true});
            } else if (decode.rol === 4) {
              await this.router.navigate(['panel'], {replaceUrl: true});
            }
          } else {
            this.snackBar.open('No tiene permiso para ingresar', 'ok', { duration: 5000 });
          }
        } else if (res?.code === 204) {
          this.snackBar.open(res?.message ? res.message : 'Usuario o contraseña inválida.', 'ok', { duration: 5000 });
        } else if (res?.code === 203) {
          this.snackBar.open(res?.message ? res.message : 'Usuario suspendido.', 'ok', { duration: 5000 });
        }
        this.loading =  false;
      }, err => {
        this.loading =  false;
        if (err?.error && err?.error?.code === 401) {
          this.snackBar.open(
            err?.error?.message ? err?.error?.message : 'Usuario o contraseña inválida.',
            'ok',
            { duration: 5000 }
          );
        } else {
          this.snackBar.open('Error inesperado', 'ok', { duration: 5000 });
        }
      });
    }
  }
}
