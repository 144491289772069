import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/services/api/api.service';
import { ToolsService } from 'src/app/services/tools/tools.service';
import { GeneratePdfService } from 'src/app/services/pdf/generate-pdf.service';
import * as moment from 'moment';

@Component({
  selector: 'app-account-movements',
  templateUrl: './account-movements.component.html',
  styleUrls: ['./account-movements.component.scss']
})
export class AccountMovementsComponent implements OnInit {
  displayedColumns: string[] = ['fecha', 'descripcion', 'name', 'caja', 'nro_fact', 'descriptrans', 'debito', 'credito'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;

  pageIndex = '0';
  pageSize = '10';
  sortBy = 'divisa';
  sortDir = 'asc';
  loading: any = false;
  form: FormGroup;
  currencies: any = [];
  stores: any = [];

  constructor(public apiService: ApiService, public tools: ToolsService, public snackBar: MatSnackBar, public pdfService: GeneratePdfService) { }

  ngOnInit(): void {
    this.initForm();
    this.getCurrenciesAndStore();
  }

  getCurrenciesAndStore() {
    this.loading = true;
    this.apiService.getCurrencies().subscribe(response => {
      if (response['code'] === 200) {
        this.currencies = response['info']['currency'];
        this.stores = response['info']['stores'];
      }
      this.loading = false;
      this.snackBar.open('Monedas y Tiendas cargadas', 'ok', { duration: 5000 });
    }, err => {
      this.loading = false;
      this.snackBar.open('Error cargando modedas y tiendas', 'ok', { duration: 5000 });
    })

  }

  clearValues() {
    this.form.get('money').setValue(null);
    this.form.get('store').setValue(null);
    this.form.get('dateStart').setValue(null);
    this.form.get('dateEnd').setValue(null);
  }

  initForm() {
    this.form = new FormGroup({
      money: new FormControl(null, [Validators.required]),
      store: new FormControl(null, [Validators.required]),
      dateStart: new FormControl(null, [Validators.required]),
      dateEnd: new FormControl(null, [Validators.required])
    });
  }

  public generatePDF(): any {
    this.pdfService.autoTable(
      ['Fecha', 'Tienda', 'Cliente', 'Caja', 'N° Fac', 'Tipo', 'Debito', 'Credito'],
      this.generateInfo(this.dataSource.data),
      'Movimiento de cuenta',
      `Movimiento de cuenta ${moment().format('YYYY-MM-DD Hms')}`,
      true,
      [
        {
          "Moneda": `Moneda: ${this.form.get('money').value.descripcion}`,
          "Tienda": `Tienda: ${this.form.get('store').value.descripcion}`,
          "Fecha": `Fecha: ${moment(this.form.get('dateStart').value).format('DD-MM-YYYY')} - ${moment(this.form.get('dateEnd').value).format('DD-MM-YYYY')}`
        }
      ]
    );
  }

  generateInfo(data) {
    let array = [];
    for (let item of data) {
      let info = [
        item.fecha,
        item.descripcion,
        item.name,
        item.caja,
        item.nro_fact,
        item.descriptrans,
        this.tools.formatDisplay(item.debito, 3, 3, '.', ',', ' '),
        this.tools.formatDisplay(item.credito, 3, 3, '.', ',', ' ')
      ];
      array.push(info);
    }
    return array;
  }

  filters() {
    this.loading = true;
    this.dataSource.data = [];
    this.apiService.filterMovements({
      start: moment(this.form.value.dateStart).format('YYYY-MM-DD'),
      end: moment(this.form.value.dateEnd).format('YYYY-MM-DD'),
      moneda: this.form.value.money.codigo,
      localidad: this.form.value.store.codigo
    }).subscribe(res => {
      this.loading = false;
      if (res['code'] == 200) {
        if (res['movements']['length'] > 0) {
          this.dataSource.data = res['movements'];
        } else {
          this.snackBar.open('No hay Movimiento', 'ok', { duration: 5000 })
        }
      }
    }, err => {
      this.loading = false;
      this.snackBar.open('Error cargando los Movimiento', 'ok', { duration: 5000 })
    })
  }

  resetIndexAndLoadData() {
    console.log(this.sort.direction);
    console.log(this.sort.active);
    this.dataSource.data = this.tools.orderList(this.dataSource.data, this.sort.active, this.sort.direction);
  }
}
