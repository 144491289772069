import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { ToolsService } from 'src/app/services/tools/tools.service';
import {ApiService} from '../services/api/api.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Location} from '@angular/common';
import * as moment from 'moment';
import {GeneratePdfService} from '../services/pdf/generate-pdf.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  displayedColumns: string[] = ['rif', 'name', 'mail', 'telefono', 'direccion', 'contacto', 'telefono_contacto', 'estado', 'acciones'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  sortBy: any;
  sortDir: any;
  search: any;
  allItems: any = [];

  loading: any = false;

  constructor(
    public tools: ToolsService,
    public api: ApiService,
    public snackBar: MatSnackBar,
    public location: Location,
    public pdfService: GeneratePdfService
  ) { }

  ngOnInit(): void {
    this.resetIndexAndLoadData();
  }

  public generatePDF(): void {
    this.pdfService.autoTable(
      ['Rif', 'Empresa', 'Email', 'Teléfono', 'Direción', 'Contácto', 'Telefono Contácto', 'Activo'],
      this.generateInfo(this.dataSource.data),
      'Empresas',
      `Empresas ${moment().format('YYYY-MM-DD Hms')}`,
      false,
      []
    );
  }

  public generateInfo(data): any {
    let array = [];
    for (let item of data) {
      let info = [
        item.rif,
        item.nombre_empresa,
        item.mail,
        item.telefono,
        item.direccion,
        item.contacto,
        item.telefono_contacto,
        item.estado === 1 ? 'Activo' : 'Desactivado'
      ];
      array.push(info);
    }
    return array;
  }

  public searchCompany(): any {
    let items;
    if (this.search.length > 1) {
      items = this.allItems.filter(elem => elem.nombre_empresa.toLocaleUpperCase().indexOf(this.search.toLocaleUpperCase()) !== -1);
    } else {
      items = this.allItems;
    }
    this.dataSource.data = items;
  }

  back(): void {
    this.location.back();
  }

  public resetIndexAndLoadData(): void {
    this.loading = true;
    this.api.getCompanies().subscribe((response: any) => {
      this.loading = false;
      console.log(response);
      this.dataSource.data = response.companies;
      this.allItems = response.companies;
    }, (err: any) => {
      this.loading = false;
      console.log(err);
    });
  }

  public changeStatusCompany(row: any): void {
    this.loading = true;
    this.api.updateStatusCompany({
      estado: row.estado,
      id: row.id
    }).subscribe((response: any) => {
      this.loading = false;
      this.snackBar.open(`El estado esta ${row.estado ? 'Activo' : 'inactivo'}`, 'ok', { duration: 5000 });
    }, (err: any) => {
      this.loading = false;
      this.snackBar.open(`Ups! No se logro modificar el estado`, 'ok', { duration: 5000 });
    });
  }

  public createCompany(): void {

  }
}
