import {Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {ApiService} from '../services/api/api.service';
import {SesionService} from '../services/sesion/sesion.service';
import {MatDialog} from '@angular/material/dialog';
import {LoadModalComponent} from '../load-modal/load-modal.component';
import {ChangeStatusComponent} from '../change-status/change-status.component';
import {DialogGenComponent} from '../dialog-gen/dialog-gen.component';
import {ToolsService} from '../services/tools/tools.service';
@Component({
  selector: 'app-company-files',
  templateUrl: './company-files.component.html',
  styleUrls: ['./company-files.component.scss']
})
export class CompanyFilesComponent implements OnInit, OnChanges {
  displayedColumns: string[] = ['company', 'files', 'currency', 'total', 'date_load', 'date_update', 'status', 'acciones'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatSort) sort: MatSort;
  sortBy: any;
  sortDir: any;

  loading: any = false;
  user: any;
  @Input() companyId: any;
  @Input() reload: any;
  currencies: any = [];
  viewPreview: any;
  contentPreview: any;
  onlyView: any = false;
  fileId: any;
  progres: any = 0;
  interval: any;

  constructor(public api: ApiService, private sesion: SesionService, public dialog: MatDialog, public tool: ToolsService) {}

  ngOnChanges(changes: SimpleChanges): any {
    if (changes?.reload?.firstChange) {
      this.user = this.sesion.getUser();
      this.getCurrencies();
    }
  }

  ngOnInit(): void {
    this.user = this.sesion.getUser();
    if (this.user.rol === 3) {
      this.companyId = this.user.id;
    }
    this.filesCompany();
    this.getCurrencies();
  }

  initInterval(time, finish: any = false): any {
    this.interval = setInterval(() => {
      if (this.progres < 95 || finish) {
        this.progres++;
      }
    }, time);
  }

  clearInterval(): any {
    clearInterval(this.interval);
  }

  public getCurrencies(): any {
    this.api.getCurrenciesCompany(this.user.id).subscribe((currencies: any) => {
      this.loading = false;
      if (currencies.code === 200) {
        this.currencies = currencies.currencies.filter((elem: any) => elem.estado === 1);
      }
    }, (err: any) => {
      this.loading = false;
    });
  }

  viewDetailFile(row): any {
    console.log('ROWS ', row);
    this.loading = true;
    this.fileId = row.fileId;
    this.api.getOneFile(row.fileId).subscribe((data: any) => {
      console.log(data);
      if (data?.header && data?.detail) {
        this.prepateData(data.header[0], data.detail);
        this.viewPreview = true;
        this.onlyView = true;
      }
      this.loading = false;
    }, (err: any) => {
      console.log(err);
      this.loading = false;
    });
  }

  prepateData(header, details): void {
    let array = [];
    array.push(['email', 'Nombre', 'Cedula', 'Monto', 'Estado']);
    for (var detail of details) {
      array.push([detail.email, detail.name, detail.id_number, detail.monto, detail.estado]);
    }

    this.contentPreview = {
        currency: {
          iso: header.iso,
          descripcion: header.descripcion,
          codigo: header.codigo
        },
        fileContent: array,
        fileName: header.nombre,
        companyId: header.companyId,
        estado: header.estado
      };
  }

  public filesCompany(): any {
    this.loading = true;
    console.log(this.companyId);
    this.api.getFiles(this.companyId).subscribe((data: any) => {
      this.dataSource.data = data;
      console.log(data);
      this.loading = false;
    }, (err: any) => {
      this.loading = false;
      console.log(err);
    });
  }

  eventPreview(e): any {
    this.viewPreview = e;
    this.onlyView = false;
    this.filesCompany();
  }

  selectFileCurrencyLoad(): void {
    const dialogRef = this.dialog.open(LoadModalComponent, {
      width: '380px',
      data: { currencies: this.currencies }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        this.contentPreview = result;
        this.contentPreview.estado = 0;
        this.viewPreview = true;
      }
      dialogRef.close();
    });
  }

  public getStatus(status): string {
    let label;
    if (status === 1) {
      label = 'Diferidos';
    } else if (status === 2) {
      label = 'Aprobados';
    } else if (status === 3) {
      label = 'Rechazado';
    }
    return label;
  }

  resetIndexAndLoadData(): void {}

  changeStatus(rowFile): void {
    if (rowFile.estadoFile === 1 ) {
      if (this.user.rol === 2 || this.user.rol === 3) { // Solo el admin puede modificar. El Lugar solo puede rechazar
        const dialogRef = this.dialog.open(ChangeStatusComponent, {
          width: '380px',
          data: { row: rowFile, rolUser: this.user.rol }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.api.getFileStatus(rowFile.fileId).subscribe((file: any ) => {
              if (file?.length > 0 && file[0]?.estado === 1) {
                this.updateStatus(result?.estado, rowFile.fileId, rowFile.estadoFile);
              } else {
                this.filesCompany();
                this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada el archivo ya ha cambiado de estado.');
              }
              console.log(file);
            }, err => {
              this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada el archivo ya ha cambiado de estado.');
            });
          }
          dialogRef.close();
        });
      }
    }
  }

  updateStatus(estado, fileId, oldStatus): any {
    this.loading = true;
    this.progres = 0;
    this.initInterval(2300);
    this.api.updateStatusFile({estado, fileId, oldStatus}).subscribe((response) => {
      if (response?.success) {
        this.clearInterval();
        this.initInterval(10, true);
        setTimeout(() => {
          this.loading = false;
          this.clearInterval();
          this.filesCompany();
        }, 3000);
      } else {
        this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada correctamente.');
        this.loading = false;
      }
    }, (err) => {
      this.loading = false;
      this.openDialog('Error', '¡Lo sentimos! la solicitud no fue procesada correctamente.');
      console.log(err);
    });
  }

  openDialog(titleModal: string, messageModal: string): void {
    const dialogRef = this.dialog.open(DialogGenComponent, {
      width: '380px',
      data: { title: titleModal, message: messageModal}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {}
      dialogRef.close();
    });
  }
}
