import { Injectable } from '@angular/core';
import * as moment from 'moment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class SesionService {
  nameUser: string = 'u53rsd4rshb0a4rG4rz0n';
  keyTime: string = 'g4rz0n-d45b04rd-4ngul4r-open-app';
  user: any;
  constructor() { }

  getKeyTime(): any {
    return localStorage.getItem(this.keyTime);
  }

  setDate(): any {
    localStorage.setItem(this.keyTime, moment().format('YYYY-MM-DD H:mm:ss'));
  }

  clearUser(): any {
    return localStorage.clear();
  }

  saveUser(obj): any {
    return localStorage.setItem(this.nameUser, JSON.stringify(obj));
  }

  getUser(): any {
    let data = localStorage.getItem(this.nameUser);
    if (data) {
      return JSON.parse(data);
    }
    return data;
  }

  decodeToken(token): any {
    return jwt_decode(token);
  }
}
