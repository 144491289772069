<div class="padding-box">
  <div class="panel-buttons">
    <h3 class="line-title">Resúmen de Clientes</h3>
    <div class="spacer"></div>
    <button (click)="generatePDF()" [disabled]="dataSource.data.length == 0"
            style="background: #e60000; margin-right: 10px;" mat-fab>
      <mat-icon>cloud_download</mat-icon>
    </button>
  </div>

  <mat-expansion-panel style="margin-bottom: 15px; margin-top: 10px;" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filtros
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form *ngIf="form" [formGroup]="form" autocomplete="off">
      <div class="panel-row">
        <div class="panel-fields">
          <mat-form-field class="example-full-width">
            <mat-label>Fechas</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate formControlName="dateStart" placeholder="Start date">
              <input matEndDate formControlName="dateEnd" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>


          <mat-form-field class="example-full-width">
            <mat-label>Filtrar por Nombre, cédula o correo</mat-label>
            <input type="text" matInput formControlName="client" [matAutocomplete]="auto" (input)="filterClients()"
                   (focusout)="focusout($event)">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of options" [value]="option">
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Monedas</mat-label>
            <mat-select formControlName="currency">
              <mat-option *ngFor="let currency of currenciesOptions" [value]="currency">
                {{ currency }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-flat-button [disabled]="loading"  (click)="getClients()">Aplicar</button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>

  <mat-card style="overflow:auto; margin-bottom: 50px;" *ngIf="displayedColumns.length > 0">
    <mat-table [dataSource]="dataSource"
               matSort
               [matSortActive]="sortBy"
               [matSortDirection]="sortDir"
               matSortDisableClear
               (matSortChange)="resetIndexAndLoadData()">

      <ng-container matColumnDef="id_number">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Cédula</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.id_number }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Nombre</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.name }}</mat-cell>
      </ng-container>

      <ng-container *ngFor="let currency of currencies" [matColumnDef]="currency">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-currency"> {{ currency }}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="mat-column-currency"> {{ tools.formatDisplay(row[currency] || 0, 3, 3, '.', ',', '') }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="options">
        <mat-header-cell *matHeaderCellDef> - </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="onViewMovements(row)">
            <mat-icon>visibility</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10,25,50,100]"
                   [pageIndex]="pageIndex"
                   [pageSize]="pageSize"
                   [length]="totalRecords"
                   showFirstLastButtons
                   (page)="onChangePaginator();"
                   [disabled]="loading">
    </mat-paginator>
  </mat-card>

  <!--
  <div *ngIf="loading" style="position: absolute;top:0;display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;">
    <mat-spinner ></mat-spinner>
  </div>
-->

  <div class="spinner-updating" *ngIf="loading">
    <mat-spinner [diameter]="25"></mat-spinner>
    Actualizando Datos
  </div>

</div>
